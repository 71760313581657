import 'react-toastify/dist/ReactToastify.css';
import { setContext } from '@sentry/nextjs';
import Cookies from 'js-cookie';

import { useEffect } from 'react';

import { useCurrentUser } from '@/auth/useCurrentUser';
import LoadingSpinner from '@/components/feedback/LoadingSpinner';
import { usePMDRouter } from '@/hooks/usePMDRouter';

// import theme, { invTheme } from '@/lib/theme';

import { setToken, unsetToken } from './auth';
import WithThemeProvider from './WithThemeProvider';

const WithPageProps = ({ children }) => {
  const currentUser = useCurrentUser();
  const userId = Cookies.get('userId');
  const redirectingTo = Cookies.get('authenticatingFrom');
  const isRedirectingTo = Boolean(redirectingTo);

  const router = usePMDRouter();

  useEffect(() => {
    if (currentUser) {
      setContext('user', currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (isRedirectingTo) {
      Cookies.remove('authenticatingFrom');
      router.push(redirectingTo as string);
    }
  }, [isRedirectingTo, router]);

  useEffect(() => {
    if (!userId && currentUser?.id) {
      Cookies.set('userId', currentUser.id);
    }
  }, [currentUser, userId]);

  const serverError = currentUser?.error?.networkError?.statusCode >= 500;

  useEffect(() => {
    if (
      userId &&
      !serverError &&
      !currentUser?.loading &&
      !currentUser?.isLoggedIn
    ) {
      unsetToken();
      window.location.reload();
    }
    if (!userId && currentUser?.id) {
      setToken(currentUser);
    }
  }, [currentUser, userId]);

  if (!currentUser.isReady) {
    return <LoadingSpinner />;
  }

  return (
    <WithThemeProvider>
      {isRedirectingTo ? 'Redirecting...' : children}
    </WithThemeProvider>
  );
};

export default WithPageProps;
