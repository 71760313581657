import { useCurrentUser } from '@/auth/useCurrentUser';

import Text from '@/components/data-display/Text';

import FullButton from '@/components/data-entry/FullButton';

import LoadingSpinner from '@/components/feedback/LoadingSpinner';

import { COMPANY_ROOT_PATH } from '@/lib/constants';

const checkIfPublicRoute = router => {
  return (
    router.asPath.startsWith('/login') ||
    router.asPath.startsWith('/unavailable') ||
    router.asPath.startsWith('/admin') || // TODO: @ScottAgirs review this
    router.asPath.startsWith('/api') || // TODO: @ScottAgirs review this
    router.asPath.startsWith('/access/data')
  );
};

export const WithAccessControl = ({ children, router }) => {
  const currentUser = useCurrentUser();
  const isPublicRoute = checkIfPublicRoute(router);

  const isCompanyRoute = router.asPath.startsWith(`${COMPANY_ROOT_PATH}/`);
  const isVerified = currentUser?.doctor?.isVerified;

  // TODO: Improve - consider fetching membership for selected company.
  const isMemberSomewhere = Boolean(currentUser?.membershipsCount);
  const canAccessDoctorDashboard = isMemberSomewhere || isVerified;

  const serverError = currentUser?.error?.networkError?.statusCode >= 500;

  if (currentUser.loading || !currentUser.isReady) {
    return <LoadingSpinner />;
  }

  if (!currentUser.isLoggedIn && !isPublicRoute) {
    router.push('/login');
    return <LoadingSpinner />;
  }

  if (serverError) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <Text dataTestId="feedBack-server-error" variant="h2">
            Server error.
          </Text>
          <div>Something went wrong. Please try again later.</div>
        </div>
      </div>
    );
  }

  if (
    !isPublicRoute &&
    !currentUser.isOnboarded &&
    !router.asPath.includes('/onboard/patient')
  ) {
    router.push('/onboard/patient');
    return <LoadingSpinner />;
  }

  if (
    !isPublicRoute &&
    !currentUser.isOnboardedDoctor &&
    !currentUser.membershipsCount &&
    (router.asPath.startsWith(`${COMPANY_ROOT_PATH}/`) ||
      router.asPath.includes('/d/'))
  ) {
    router.push('/onboard/doctor');
    return <LoadingSpinner />;
  }

  if (isCompanyRoute && !canAccessDoctorDashboard) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <Text dataTestId="feedBack-unverified-doctor" variant="h2">
            Verification in progress.
          </Text>
          <div>Your doctor profile is being verified or is restricted.</div>

          <div className="mt-7 flex gap-4 flex-wrap justify-center items-center">
            <FullButton href="/">Patient Dashboard</FullButton>

            <FullButton
              variant="outline"
              href="mailto:team@pocketmd.ca?subject=Doctor verification inquiry"
            >
              Contact Us
            </FullButton>
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
