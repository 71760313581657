import { useCurrentUser } from '@/auth/useCurrentUser';

import Text from '@/components/data-display/Text';
import UserAvatar from '@/components/data-display/UserAvatar';
import SmartLink from '@/components/navigation/SmartLink';
import { usePMDRouter } from '@/hooks/usePMDRouter';

import { profileSettingsHref } from '@/lib/constants';

const UserProfileBadge = ({
  onClickSideEffect,
}: {
  onClickSideEffect?: () => void;
}) => {
  const router = usePMDRouter();
  const currentUser = useCurrentUser();

  return (
    <SmartLink
      onClick={() => {
        router.push(profileSettingsHref);
        if (onClickSideEffect) onClickSideEffect();
      }}
      className="w-full"
    >
      <div
        data-testid="link-edit-profile"
        className="flex items-center bg-surface flex-shrink-0 w-full group rounded-full border p-2"
      >
        <div>
          <UserAvatar user={currentUser} size="36px" />
        </div>

        <div className="ml-3 text-left">
          <Text
            className="text-sm font-medium text-gray-700 group-hover:text-gray-900"
            variant="h5"
          >
            {currentUser?.firstName || 'User Name'}
          </Text>
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            Edit profile
          </p>
        </div>
      </div>
    </SmartLink>
  );
};

export default UserProfileBadge;
