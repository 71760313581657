import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/nextjs';
import get from 'lodash/get';

import { ErrorCodes, errorCodesDictionary } from '@/lib/errorCodesDictionary';
import { messagesEN } from '@/lib/statusCodes/messagesEN'; // Deprecated

import { isBrowserTrackingBlocked } from './browser';

type CustomErrorParams = {
  code?: string;
  extensions?: Record<string, any>;
  originalError?: any;
};

/**
 * CustomError class extends the built-in Error class to include additional properties.
 * @class
 * @extends Error
 * @property {string} code - The error code.
 * @property {string} message - The error message.
 * @property {Record<string, any>} extensions - Additional error information.
 * @property {any} originalError - The original error object.
 *
 * @returns {CustomError} A new CustomError instance.
 */
export class CustomError extends Error {
  code: string;
  message: string;
  extensions: Record<string, any>;
  originalError: any;

  /**
   * Constructs a new CustomError instance.
   *
   * @param {Object} params - The parameters for the error.
   * @param {string} [params.code='unknown.common'] - The error code.
   * @param {Record<string, any>} [params.extensions={}] - Additional error information.
   * @param {any} params.originalError - The original error object.
   */
  constructor({
    code = 'unknown.unhandled',
    extensions = {},
    originalError,
  }: CustomErrorParams) {
    const errorCode = code;
    const errorMessage = get(
      errorCodesDictionary,
      code,
      get(
        errorCodesDictionary,
        `${code.split('.').slice(0, -1).join('.')}.generic`,
        'Something went wrong!'
      )
    );

    super(errorMessage);

    this.code = errorCode;
    this.message = errorMessage;
    this.extensions = extensions;
    this.originalError = originalError;
  }
}

/**
 * Retrieves a custom error object based on the provided error from different sources to get a unified error with proper code and UI message.
 *
 * @param error - The error object to be converted to a custom error.
 * @returns A custom error object.
 */
export const getCustomError = (error: any): CustomError => {
  if (error instanceof CustomError) {
    return error;
  }

  // Get custom error from GQL back-end
  if (error?.extensions?.code && !(error instanceof ApolloError)) {
    return new CustomError({
      code: error.extensions.code,
      extensions: error.extensions,
      originalError: error,
    });
  }

  // Get custom error from network/server errors, e.g. HTTP 5xx
  if (error?.name === 'ServerError') {
    return new CustomError({
      code: 'network.server.unavailable',
      originalError: error,
    });
  }

  // Get custom error from ApolloError
  if (error instanceof ApolloError) {
    const networkError = get(error, 'networkError', null);

    if (networkError) {
      return new CustomError({
        code: 'network.generic',
        originalError: error,
      });
    }

    const graphQLErrors = get(error, 'graphQLErrors', []);

    if (graphQLErrors.length) {
      // TODO: Extend with error array handling
      const { extensions } = graphQLErrors[0];
      // Get error code from extensions, if not found, use default code
      const code = get(extensions, 'code', 'unknown.gql');

      return new CustomError({
        code,
        extensions,
        originalError: error,
      });
    }
  }

  return new CustomError({
    code: 'runtime.unhandled',
    originalError: error,
  });
};

/**
 * Converts an error to a custom error, optionally logs it to Sentry and returns the error code and message to be displayed to the user or continue with the error extra handling.
 *
 * @param {any} error - The error to handle.
 * @param {boolean} [shouldReport=false] - Whether the error should be reported.
 * @returns {{ code: string, message: string }} An object containing the error code and message.
 */
export const handleError = (error: any, shouldReport?: boolean) => {
  const customError = getCustomError(error);

  const { code, message } = customError;

  if (process.env.NODE_ENV === 'development') {
    const enrichedConsoleMsg = `[DEV] ❌ [${code}] error: ${message}`;

    // eslint-disable-next-line no-console
    console.error(enrichedConsoleMsg);
    // eslint-disable-next-line no-console
    console.dir(customError);
  } else {
    isBrowserTrackingBlocked().then(trackingBlocked => {
      // eslint-disable-next-line no-console
      console.info(`trackingBlocked:`, trackingBlocked);

      const isFlowViolationError = code.startsWith(ErrorCodes.FlowViolation);

      if (shouldReport && !isFlowViolationError) {
        if (trackingBlocked) {
          // eslint-disable-next-line no-console
          console.error('Sentry is blocked, with error', { error });
        } else {
          captureException(customError, {
            extra: {
              code: customError.code,
              extensions: customError.extensions,
              originalError: customError.originalError,
            },
          });
        }
      }
    });
  }

  return { code, message };
};

// Deprecated
export const parseErrorMessage = (errMsg: string) => {
  if (!errMsg) return {};

  const errorIntent = errMsg.split(':')[0];
  const code = errorIntent.split('(')[1]?.replace(')', '') || '0.0.0.0'; // TODO: define default code
  const [groupCode, moduleCode, subModuleCode, messageCode] = code.split('.');

  return {
    code,
    groupCode,
    message: messagesEN[code] || 'Something went wrong!',
    messageCode,
    moduleCode,
    subModuleCode,
  };
};
