import { NextRouter, Url } from 'next/dist/shared/lib/router/router';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { COMPANY_ROOT_PATH } from '@/lib/constants';
import { CustomError, handleError } from '@/utils/errors';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

const TRACK_TIMEOUT = 5000;
const TRACK_ATTEMPTS = 10;

const trackRouterLoopFactory = () => {
  let pathHistory: string[] = [];

  let trackRouterLoopCallingCount = 0;
  let trackRouterLoopTimerId = setInterval(() => {
    trackRouterLoopCallingCount = 0;
    pathHistory = [];
  }, TRACK_TIMEOUT);

  function track(asPath) {
    pathHistory.push(asPath);

    trackRouterLoopCallingCount += 1;

    if (trackRouterLoopCallingCount > TRACK_ATTEMPTS) {
      trackRouterLoopCallingCount = 0;

      const customError = new CustomError({
        code: 'application.routerLoop',
        extensions: {
          pathHistory,
        },
      });

      handleError(customError);

      clearInterval(trackRouterLoopTimerId);

      trackRouterLoopTimerId = setInterval(() => {
        trackRouterLoopCallingCount = 0;
        pathHistory = [];
      }, TRACK_TIMEOUT);

      // Redirect to the home page to prevent the infinite loop
      window.location.replace('/');
    }
  }

  return track;
};

const trackRouterLoop = trackRouterLoopFactory();

const validateCUID = (cuid: string): boolean => {
  // Regex breakdown:
  // ^c                     : Must start with 'c'
  // [0-9a-z]{7,}           : Timestamp, a base-36 string of at least 7 characters
  // [0-9a-z]{4}            : Counter, a 4-character base-36 string
  // [a-zA-Z0-9]{4}         : Client fingerprint, a 4-character alphanumeric string
  // [0-9a-z]{8,}           : Random string, a base-36 string of at least 8 characters
  const cuidRegex = /^c[0-9a-z]{7,}[0-9a-z]{4}[a-zA-Z0-9]{4}[0-9a-z]{8,}$/;

  return cuidRegex.test(cuid);
};

/**
 * Extracts the `companyId`(cuid) from a given path, ensuring it's not a static page like `/page`.
 *
 * @param {string} path - The path including the `/company/[companyId]` or `/company/page`.
 * @returns {string | null} - The `companyId` if it exists, otherwise `null`.
 */
const extractCompanyId = (path: string) => {
  const regExp = new RegExp(
    `^${COMPANY_ROOT_PATH.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\/([^/]+)`
  );
  const match = path.match(regExp);

  if (match) {
    const potentialCompanyId = match[1];

    if (!validateCUID(potentialCompanyId)) {
      return null;
    }

    return potentialCompanyId;
  }

  return null;
};

/**
 * Constructs a new path by removing the `[companyId]` segment.
 * @param {string} path - The original path including `[companyId]`.
 * @returns {string} - The path without `[companyId]`.
 */
const getPathWithoutCompanyId = (path: string) => {
  const companyId = extractCompanyId(path);

  if (companyId) {
    return path.replace(`/${companyId}`, '');
  }

  return path;
};

/**
 * Constructs a company-specific URL by dynamically injecting or extracting the `companyId` from the provided URL or the Next.js router.
 * This function supports both string and object forms of the `url` parameter.
 *
 * ### Behavior:
 * - **For string URLs:** The function attempts to extract the `companyId` from the URL string itself. If not found, it falls back to the `companyId` present in the router's query parameters. The `companyId` is then used to generate a company-specific URL.
 * - **For object URLs:** The function modifies the URL object to include the `companyId` in both the query and the pathname, ensuring that the resulting URL is correctly scoped to the company.
 *
 * ### Usage:
 * - Typically used in situations where a user should be navigated to a company-specific section of the application, ensuring that the `companyId` is always part of the URL structure.
 *
 * @param {Url | string} url - The URL that can be either a string representing the pathname, or an object that conforms to Next.js's `Url` type.
 * If it's a string, the function will attempt to extract the `companyId` from it or use the one present in the router's query. If it's an object, it will modify the `pathname` and `query` based on the `companyId`.
 * @param {NextRouter} router - The Next.js router object, used to access the current query parameters, including the `companyId`.
 * If the `companyId` cannot be extracted from the `url`, the function will use the `companyId` from the router's query.
 *
 * @returns {Url | string} - Returns a URL that is company-specific:
 * - **For string input:** Returns a string URL with the company ID injected into the correct location.
 * - **For object input:** Returns an updated URL object with the `pathname` and `query` modified to include the company ID.
 *
 * @example
 * const companyUrl = getCompanyUrl('/dashboard', router);
 * console.log(companyUrl); // Output: '/company/12345/dashboard' assuming companyId is '12345' in router's query.
 */
const getCompanyUrl = (url: Url, router: NextRouter) => {
  const queryCompanyId = router.query.companyId as string;

  const companyId =
    typeof url === 'string'
      ? extractCompanyId(url) || queryCompanyId
      : (typeof url.query !== 'string' && url.query?.companyId) ||
        queryCompanyId;

  const companyPath = companyId
    ? `${COMPANY_ROOT_PATH}/${companyId}`
    : COMPANY_ROOT_PATH;

  const path = typeof url === 'string' ? url : url.pathname;

  let fullPath;

  if (path) {
    let sanitizedPath;

    if (extractCompanyId(path) || path.includes('[companyId]')) {
      sanitizedPath = path
        .replace(COMPANY_ROOT_PATH, '')
        .replace(/^\/[^/]+/, '');
    } else {
      sanitizedPath = path.replace(COMPANY_ROOT_PATH, '');
    }

    const validPath = sanitizedPath.startsWith('/')
      ? sanitizedPath
      : `/${sanitizedPath}`;

    fullPath = `${companyPath}${validPath === '/' ? '' : validPath}`;
  }

  let companyUrl;

  if (typeof url === 'string') {
    companyUrl = fullPath;
  } else {
    url.query = {
      ...((typeof url.query !== 'string' && url.query) || {}),
      companyId:
        (typeof url.query !== 'string' && url.query?.companyId) || companyId,
    };
    url.pathname = url.query?.companyId ? url.pathname : fullPath;
    companyUrl = url;
  }

  return companyUrl;
};

/**
 * Checks if the given path belongs to a specific company.
 *
 * @param path - The path to be checked.
 * @param companyId - The ID of the company.
 * @returns A boolean indicating whether the path belongs to the company or not.
 */
const checkCompanyPath = (path?: string | null, companyId?: string) => {
  if (!path && companyId) {
    return true;
  }

  if (path) {
    return path?.startsWith(COMPANY_ROOT_PATH);
  }

  return false;
};

/**
 * Custom hook that extends the Next.js `useRouter` hook to provide additional functionality
 * for handling routes with company-specific paths. This hook ensures that routing within the
 * application properly handles URLs that are prefixed with a company identifier, simplifying
 * navigation logic.
 *
 * ### Functionality:
 * - **Base Path Calculation:** Computes a `basePath` that excludes the company identifier from the current path. This is useful for scenarios where you need to work with or display a path that is agnostic of the company context.
 * - **Enhanced Push/Replace:** Overrides the default `push` and `replace` methods to automatically insert the company identifier into the URL when navigating to routes that require it.
 *
 * ### Returned Object:
 * - **router:** The Next.js router object, containing all properties and methods provided by the `useRouter` hook.
 * - **basePath:** A string representing the current path with the company identifier removed, useful for routing logic or UI that should not include the company context.
 * - **push:** A custom `push` method that behaves like `router.push`, but automatically handles the company identifier in the path.
 * - **replace:** A custom `replace` method that behaves like `router.replace`, but automatically handles the company identifier in the path.
 *
 * @returns {Object} An object containing:
 *   - **router:** The original Next.js router object with additional functionalities.
 *   - **basePath:** The current path without the company identifier.
 *   - **push:** A custom `push` method that handles routing with company-specific paths.
 *   - **replace:** A custom `replace` method that handles routing with company-specific paths.
 *
 */
export const usePMDRouter = () => {
  const router = useRouter();

  const basePath = useMemo(
    () => getPathWithoutCompanyId(router.asPath),
    [router.asPath]
  );

  const push = useCallback(
    (url: Url, as?: Url, options?: TransitionOptions) => {
      trackRouterLoop(router.asPath);

      const path = typeof url === 'string' ? url : url.pathname;

      const companyId = router.query.companyId as string;

      const isCompanyPath = checkCompanyPath(path, companyId);

      if (!isCompanyPath) {
        router.push(url, as, options);
        return;
      }

      const companyUrl = getCompanyUrl(url, router);

      router.push(companyUrl, as, options);
    },
    [router]
  );

  const replace = useCallback(
    (url: Url, as?: Url, options?: TransitionOptions) => {
      trackRouterLoop(router.asPath);

      const path = typeof url === 'string' ? url : url.pathname;

      const companyId = router.query.companyId as string;

      const isCompanyPath = checkCompanyPath(path, companyId);

      if (!isCompanyPath) {
        router.replace(url, as, options);
        return;
      }

      const companyUrl = getCompanyUrl(url, router);

      router.replace(companyUrl, as, options);
    },
    [router]
  );

  return useMemo(
    () => ({
      ...router,
      basePath,
      push,
      replace,
    }),
    [router, basePath, push, replace]
  );
};
