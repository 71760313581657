import FullButton from '@/components/data-entry/FullButton';

import UserProfileBadge from '@/components/User/UserProfileBadge';
import { usePMDRouter } from '@/hooks/usePMDRouter';

import { signOut } from '@/lib/auth';

import DoctorAppIconMenuItem from './DoctorAppIconMenuItem';
import FullLogoLink from './FullLogoLink';
import { IconMenuItem } from './IconMenuItem';
import { MgmtAppIconMenuItem } from './MgmtAppIconMenuItem';
import { PATIENT_SIDEBAR_MENU_ITEMS } from './patient-sidebar';
import { PatientSidebarMiscLink } from './PatientSidebarMiscLink';

export const PatientSidebarContent = ({
  onClickSideEffect,
}: {
  onClickSideEffect?: () => void;
}) => {
  const router = usePMDRouter();
  const { asPath } = router;

  return (
    <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-background overflow-y-auto">
      {/* Sidebar + NAV */}
      <div className="flex-1 flex flex-col pt-5 pb-4">
        {/* LOGO */}
        <div className="flex items-center flex-shrink-0 px-4">
          <FullLogoLink />
        </div>

        <nav className="mt-5 flex-1 px-3 bg-background space-y-1">
          {PATIENT_SIDEBAR_MENU_ITEMS.map(item => {
            const isActive =
              (item.href !== '/' && asPath.includes(item.href)) ||
              (item.href === '/' && asPath === '/');

            return (
              <IconMenuItem
                key={item.label}
                label={item.label}
                onClick={() => {
                  router.push(item.href);
                  if (onClickSideEffect) onClickSideEffect();
                }}
                icon={item.icon}
                isActive={isActive}
              />
            );
          })}

          <div className="py-12">
            <FullButton
              data-testid="btn-sidebar-find-doctor"
              block
              shape="round"
              onClick={() => {
                router.push('/explore?view=physicians&eventType=virtual');
                if (onClickSideEffect) onClickSideEffect();
              }}
            >
              Find a Doctor
            </FullButton>
          </div>

          <PatientSidebarMiscLink />
        </nav>
      </div>

      <div className="px-4">
        <DoctorAppIconMenuItem />
        <MgmtAppIconMenuItem />

        <IconMenuItem
          className="md:hidden"
          icon="sign-out"
          onClick={signOut}
          label="Sign Out"
        />
      </div>

      {/* User Badge */}
      <div className="p-3">
        <UserProfileBadge onClickSideEffect={onClickSideEffect} />
      </div>
    </div>
  );
};

export const PatientSidebar = () => {
  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <PatientSidebarContent />
    </div>
  );
};
