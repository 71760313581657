/**
 * Class Names
 */
export const inputBorderWidth = 'border-border border';
export const inputRingWidth =
  'ring-0 focus:ring-1 focus:ring-offset-1 focus:outline-none';

export const inputHeightClassXs = 'h-input-xs';
export const inputHeightClassSm = 'h-input-sm';
export const inputHeightClassMd = 'h-input-md';
export const inputHeightClassLg = 'h-input-lg';
export const inputHeightClassXl = 'h-input-xl';

export const maxUploadSizeInBytes = 5 * 1024 * 1024;
export const PMD_FEE_MULTIPLIER = 0.0399;
export const PMD_FEE_PERCENTAGE = Math.round(PMD_FEE_MULTIPLIER * 10000) / 100;

export const IN_ROOM_STATUS_INTERVAL = 3 * 60 * 1000;
export const IN_ROOM_STATUS_VALID_INTERVAL = (3 * 60 + 30) * 1000;

/**
 * Date & Time
 */

export const dateFormat = 'YYYY/MM/DD';

/**
 * Links
 */
// Profile
export const profileSettingsHref = '/profile';
export const profileHealthCardsHref = '/profile/health-card';
export const profileEmergencyContactHref = '/profile/emergency-contact';

// Appointment Room
export const doctorApptRoomHref = '/appointment/room/d';
export const patientApptRoomHref = '/appointment/room';

export const DEFAULT_APPOINTMENT_TYPE = 'virtual';

// Patients
export const PATIENT_ROOT_PATH = '/patient';

// Companies
export const COMPANY_ROOT_PATH = '/co';
export const COMPANY_DASHBOARD_PATH = `${COMPANY_ROOT_PATH}/dashboard`;
export const COMPANY_WELCOME_PATH = `${COMPANY_ROOT_PATH}/welcome`;

export const COMPANY_ADD_PATIENT_PATH = `${COMPANY_ROOT_PATH}/add-patient`;
export const COMPANY_ONBOARDED_PATIENTS_PATH = `${COMPANY_ROOT_PATH}/patients/onboarded`;
export const COMPANY_INVITED_PATIENTS_PATH = `${COMPANY_ROOT_PATH}/patients/invited`;

export const COMPANY_APPOINTMENTS_UPCOMING_PATH = `${COMPANY_ROOT_PATH}/appointments/upcoming`;
export const COMPANY_APPOINTMENTS_REQUESTS_PATH = `${COMPANY_ROOT_PATH}/appointments/requests`;
export const COMPANY_APPOINTMENTS_MISSED_PATH = `${COMPANY_ROOT_PATH}/appointments/missed`;
export const COMPANY_APPOINTMENTS_COMPLETED_PATH = `${COMPANY_ROOT_PATH}/appointments/completed`;

export const COMPANY_FAXES_RECEIVED_PATH = `${COMPANY_ROOT_PATH}/faxes/received`;
export const COMPANY_FAXES_SENT_PATH = `${COMPANY_ROOT_PATH}/faxes/sent`;

export const COMPANY_TEST_ORDERED_PENDING_PATH = `${COMPANY_ROOT_PATH}/tests-ordered/pending`;
export const COMPANY_TEST_ORDERED_RESULTS_PATH = `${COMPANY_ROOT_PATH}/tests-ordered/results`;

export const COMPANY_BILLINGS_PATH = `${COMPANY_ROOT_PATH}/billing`;

export const COMPANY_TASKS_OPEN_PATH = `${COMPANY_ROOT_PATH}/tasks/open`;
export const COMPANY_TASKS_RESOLVED_PATH = `${COMPANY_ROOT_PATH}/tasks/resolved`;

export const COMPANY_TEAM_PATH = `${COMPANY_ROOT_PATH}/team`;

export const COMPANY_CLINICS_PATH = `${COMPANY_ROOT_PATH}/clinics`;

/**
 * Layout
 */

// Heights
export const headerHeight = 64;
export const headerHeightPx = `${headerHeight}px`;

export const breakPointValues = ['30em', '40em', '52em', '64em', '80em'];
export const breakpoints = {
  md: 768,
  mobile: 468,
  mobileLg: 600,
  tabletLg: 960,
  // eslint-disable-next-line sort-keys
  desktop: 1224,
  desktopLg: 1354,
};

// Add px suffix
export const breakpointsPx = Object.fromEntries(
  // convert to array, map, and then fromEntries gives back the object
  Object.entries(breakpoints).map(([key, value]) => [key, `${value}px`])
);

// Creates an array of breakpoints px values: ["468px", "600px" ...]
export const breakpointsArray = Object.values(breakpoints).sort(
  (a, b) => a - b
);
export const breakpointsPxArray = Object.values(breakpointsPx);

// These queries are for mobile first designs
// @TODO: (Scott) Add documentation
export const breakpointQueries = Object.fromEntries(
  Object.entries(breakpointsPx).map(([key, value]) => [
    key,
    `@media (min-width: ${value})`,
  ])
);

/**
 * Borders
 */

// Widths
export const borderWidth = 1;
export const borderWidthPx = `${borderWidth}px`;
export const responsiveSlideoutWidths = [
  '90vw',
  '90vw',
  '90vw',
  '80vw',
  '70vw',
  '50vw',
];

// Radiuses
export const borderRadiusSm = 2;
export const borderRadiusSmPx = `${borderRadiusSm}px`;
export const borderRadius = 4;
export const borderRadiusPx = `${borderRadius}px`;

// CARD
export const cardRadius = '0.75em';
export const cardRadiusXXS = '3px';
export const cardRadiusXS = '4px';
export const cardRadiusSM = '8px';
export const cardRadiusLG = '1.25em';

/**
 * Inputs
 */

// Radiuses
export const inputRadius = '0.25em';
export const inputRadiusSM = '0.125em';
export const inputRadiusLG = '5em';

// Heights
export const inputHeightTiny = 24;
export const inputHeightSm = 32;
export const inputHeightMd = 40;
export const inputHeightLg = 64;

// Heights in Pixels
export const inputHeightTinyPx = `${inputHeightTiny}px`;
export const inputHeightSmPx = `${inputHeightSm}px`;
export const inputHeightMdPx = `${inputHeightMd}px`;
export const inputHeightLgPx = `${inputHeightLg}px`;

// Input Text sizes
export const textSizeTag = `0.75rem`;
export const textSizeSm = `0.75rem`;
export const textSizeMd = `1rem`;
export const textSizeLg = `1rem`;
export const textSizeXl = `1.25rem`;

// Buttons
export const primaryButtonBorderRadius = '4px';
export const primaryButtonBorderRadiusSm = '2px';

// Common Classnames
export const extraPromptPadding = 'pt-12 px-8';
export const extraXPadding = 'px-4';

// Privacy Policy Link
export const privacyPolicyLink = 'https://www.pocketmd.ca/privacy.html';
export const termsOfServiceLink = 'https://www.pocketmd.ca/service.html';

// Core Stacks
export const monoFontStack = 'Monaco, sans-serif';
export const sansSerifFontStack = 'GTZirkon, sans-serif';
export const serifFontStack = 'Canela, serif';
export const fancyFontStack = 'TheDoctorFont, serif';

// Global UI Stacks
export const buttonFontStack = sansSerifFontStack;
export const bodyFontStack = sansSerifFontStack;
export const eyebrowFontStack = sansSerifFontStack;
export const captionTextFontStack = sansSerifFontStack;
export const headingFontStack = serifFontStack;
export const inputFontStack = bodyFontStack;
