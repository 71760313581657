import Link from 'next/link';

import SVG, { PredefinedIconsTypes } from '@/components/data-display/SVG';

import { classNames } from '@/utils/classNames';

export const IconMenuItem = ({
  className: providedClassName,
  href,
  isActive,
  label,
  labelExtra,
  onClick,
  type = 'button',
  icon,
  title,
}: {
  className?: string;
  href?: URL | string;
  isActive?: boolean;
  label: string;
  labelExtra?: false | string | JSX.Element;
  onClick?: (e?: any) => void;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
  icon: PredefinedIconsTypes | JSX.Element;
}) => {
  const Icon = icon;

  const className = classNames(
    isActive
      ? 'group bg-surface text-text border-r-red-700 border-r-4'
      : 'text-gray-600 hover:bg-surface hover:text-text hover:border-r-primary',
    'group flex items-center px-2 py-2 text-sm font-medium rounded w-full border-r-4 border-r-transparent',
    providedClassName
  );

  const renderContent = () => {
    if (!Icon) {
      // eslint-disable-next-line no-console
      console.warn("IconMenuItem: 'icon' prop is required.");
    }

    return (
      <>
        {typeof Icon === 'function' && (
          // TODO: Fix typings
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Icon
            className={classNames(
              isActive ? '' : 'group-hover:text-gray-500',
              'fill-current mr-3 flex-shrink-0 h-4 w-4 group-hover:fill-primary'
            )}
            aria-hidden="true"
          />
        )}

        {(typeof Icon === 'string' || typeof Icon === 'object') && (
          <SVG
            icon={Icon}
            className={classNames(
              isActive ? '' : 'group-hover:text-gray-500',
              'fill-current mr-3 flex-shrink-0 h-4 w-4 group-hover:fill-primary'
            )}
            aria-hidden="true"
          />
        )}
        {label}

        {labelExtra}
      </>
    );
  };

  if (onClick) {
    return (
      <button
        data-testid={`btn-${label}`}
        className={className}
        type={type}
        onClick={onClick}
        title={title}
      >
        {renderContent()}
      </button>
    );
  }

  return (
    <Link
      key={label}
      title={title}
      href={href as URL}
      data-testid={`link-${label}`}
      className={className}
    >
      {renderContent()}
    </Link>
  );
};
