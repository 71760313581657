import Link from 'next/link';

import { classNames } from 'primereact/utils';
import { ComponentProps } from 'react';

import { usePMDRouter } from '@/hooks/usePMDRouter';

import { COMPANY_ROOT_PATH } from '@/lib/constants';

type CompanyLinkProps = Omit<ComponentProps<typeof Link>, 'href'> & {
  href: string;
};

export const CompanyLink = ({
  children,
  href,
  className: providedClassName,
  ...rest
}: CompanyLinkProps) => {
  const router = usePMDRouter();
  const companyId = router.query.companyId as string;

  const companyPath = companyId
    ? `${COMPANY_ROOT_PATH}/${companyId}`
    : COMPANY_ROOT_PATH;

  const sanitizedPath = href.replace(COMPANY_ROOT_PATH, '');
  const validPath = sanitizedPath.startsWith('/')
    ? sanitizedPath
    : `/${sanitizedPath}`;
  const fullPath = `${companyPath}${validPath === '/' ? '' : validPath}`;

  const className = classNames(
    'transition-all duration-100 rounded',
    'ring-primary ring-0 ring-offset-0 ring-opacity-0',
    providedClassName
  );

  return (
    <Link href={fullPath} className={className} {...rest}>
      {children}
    </Link>
  );
};
