import { useState } from 'react';

import { errorToast } from '@/components/feedback/utils/doNotify';

import { useCurrentUserQuery, AuthenticatedItem } from '@/generated/graphql';

import { signOut } from '@/lib/auth';
import { getUserNameVariants } from '@/utils/getUserNameVariants';

export interface CurrentUser extends Partial<AuthenticatedItem> {
  doctorId?: string;
  fullName?: string;
  isLoggedIn?: boolean;
  isManager?: boolean;
  isReady?: boolean;
  loading?: boolean;
  patientId?: string;
  roleNames?: string[];
  signOut?: typeof signOut;
  stripeCusId?: string;
  userId?: string;
  error?: any;
}

export const useCurrentUser = (): CurrentUser => {
  const [isReady, setReady] = useState(false);
  const { data, loading, error } = useCurrentUserQuery({
    onCompleted: () => {
      setReady(true);
    },
  });

  if (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const serverError = error.networkError?.statusCode >= 500;

    if (!serverError) {
      errorToast(error);
    }

    return {
      error,
      isReady: true,
      loading: false,
    };
  }

  if (loading) return { loading: true };

  const currentUser = data?.authenticatedItem;

  if (!currentUser) {
    return {
      isReady: true,
      loading: false,
    };
  }

  const userId = currentUser.id;
  const doctorId = currentUser.doctor?.id;
  const patientId = currentUser.patient?.id;

  const isLoggedIn = Boolean(userId);

  const { fullName } = getUserNameVariants(currentUser, true);

  const roleNames = (currentUser.roles?.map(role => role.name) ||
    []) as string[];
  const isAdmin = roleNames.includes('sysAdmin') || currentUser.isAdmin;

  return {
    ...currentUser,
    doctorId,
    fullName,
    isAdmin,
    isLoggedIn,
    isManager: Boolean(roleNames.includes('sysManager') || isAdmin),
    isReady,
    patientId,
    roleNames,
    signOut,
    stripeCusId: currentUser.stripeCusId as string,
    userId,
  };
};
