import { PredefinedIconsTypes } from '@/components/data-display/SVG';

export type MenuItem = {
  href: string;
  icon: PredefinedIconsTypes | JSX.Element;
  label: string;
};
export const PATIENT_SIDEBAR_MENU_ITEMS: MenuItem[] = [
  {
    href: '/',
    icon: 'house-blank',
    label: 'Dashboard',
  },
  {
    href: '/appointments/upcoming',
    icon: 'calendar-days',
    label: 'Appointments',
  },
  {
    href: '/doctors',
    icon: 'user-nurse',
    label: 'My Doctors',
  },
  {
    href: '/medical-history/notes',
    icon: 'history',
    label: 'Medical History',
  },
  // {
  //   href: '/messages',
  //   icon: 'messages',
  //   label: 'Messages',
  // },
];
