import '../styles/global.css';
import { ApolloProvider } from '@apollo/client';
import { Analytics } from '@vercel/analytics/react';

import { useRouter } from 'next/router';
import {
  PagesProgressBar as ProgressBar,
  stopProgress,
} from 'next-nprogress-bar';

import { addLocale, PrimeReactProvider } from 'primereact/api';
import { usePassThrough } from 'primereact/passthrough';
import Tailwind from 'primereact/passthrough/tailwind';

import { Fragment, useEffect } from 'react';
import { useIsClient } from 'usehooks-ts';

import { FeedbackModal } from '@/components/feedback/FeedbackModal';
import { UnavailableModal } from '@/components/feedback/UnavailableModal';

import { RouterContext } from '@/context';

import { usePMDRouter } from '@/hooks/usePMDRouter';

import { DefaultLayout } from '@/layouts/DefaultLayout';
import { DoctorMobileUnavailableLayout } from '@/layouts/DoctorMobileUnavailableLayout';

import { useApollo } from '@/lib/apolloClient';
import { AppErrorBoundary } from '@/lib/AppErrorBoundary';
import { SessionProvider } from '@/lib/auth';
import { CustomTheme } from '@/lib/CustomTheme';
import GlobalErrorBoundary from '@/lib/GlobalErrorBoundary';
import { WithAccessControl } from '@/lib/WithAccessControl';
import WithPageProps from '@/lib/WithPageProps';
import { WithUserSession } from '@/lib/WithUserSession';

addLocale('ca', {
  firstDayOfWeek: 1,
});

const App = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps);
  const isTempDoctorMobileDisabled = Component.shouldPreventMobileAccess;
  const InterceptLayout = isTempDoctorMobileDisabled
    ? DoctorMobileUnavailableLayout
    : Fragment;
  const Layout = Component.Layout || DefaultLayout;
  const router = usePMDRouter();
  const isClient = useIsClient();

  const nextRouter = useRouter();

  useEffect(() => {
    nextRouter.events.on('routeChangeComplete', () => {
      stopProgress();
    });

    return () => {
      nextRouter.events.off('routeChangeComplete', () => true);
    };
  }, []);

  const CustomTailwindTheme = usePassThrough(Tailwind, CustomTheme, {
    mergeProps: false,
    mergeSections: true,
  });

  if (!isClient) return null; // TODO: Add server/guest view

  return (
    <PrimeReactProvider value={{ pt: CustomTailwindTheme, unstyled: true }}>
      <GlobalErrorBoundary>
        <SessionProvider session={pageProps.session} refetchInterval={0}>
          <ApolloProvider client={apolloClient}>
            <WithUserSession>
              <UnavailableModal />

              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <WithPageProps>
                <RouterContext.Provider value={router}>
                  <WithAccessControl router={router}>
                    <Layout>
                      {/* <UserPrompts>
                    {({ currentUser, loading }) => {
                      if (loading) return <LoadingSpinner />;

                      const serverError =
                        currentUser?.error?.networkError?.statusCode >= 500;

                      const userPrompt = prompts?.length && prompts[0];

                      if (
                        userPrompt?.promptValue &&
                        !router.asPath.includes(userPrompt.promptValue)
                      ) {
                        router.push(userPrompt.promptValue);

                        // This loading spinner ensures that there's a visual loading feedback
                        // while the router is redirecting (in case it's slow).
                        return <FullScreenLoading />;
                      }

                      return ( */}

                      <FeedbackModal />

                      <AppErrorBoundary>
                        <InterceptLayout>
                          <Component {...pageProps} />
                          <ProgressBar
                            height="4px"
                            color="#37585e"
                            options={{ showSpinner: false }}
                            shallowRouting
                          />
                          <Analytics />
                        </InterceptLayout>
                      </AppErrorBoundary>

                      {/* );
                    }}
                  </UserPrompts> */}
                    </Layout>
                  </WithAccessControl>
                </RouterContext.Provider>
              </WithPageProps>
            </WithUserSession>
          </ApolloProvider>
        </SessionProvider>
      </GlobalErrorBoundary>
    </PrimeReactProvider>
  );
};

export default App;
