import { useEffect, useState } from 'react';

import Text from '@/components/data-display/Text';

import FullButton from '@/components/data-entry/FullButton';

import { isFeedbackModalOpenVar } from '@/shared-state/common/feedback';

import { handleError } from '@/utils/errors';

export const ErrorFallback = ({
  error,
  fallbackMessage,
  shouldHideFeedback = true, // temp disable feedback
  shouldReport = true,
}: {
  error?: any;
  fallbackMessage?: string;
  shouldHideFeedback?: boolean;
  shouldReport?: boolean;
}) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (error) {
      const { message } = handleError(error, shouldReport);
      setTitle(fallbackMessage || message || 'Something unexpected happened.');
    }
  }, [error, shouldReport]);

  const reloadPage = () => {
    window.location.reload();
  };

  const goToStart = () => {
    window.location.replace('/');
  };

  const reportIssue = () => {
    // TODO: implement error reporting
    // const errorString = handleError(error, false);
    // Temporary plug:
    isFeedbackModalOpenVar(true);
  };

  return (
    <div className="w-full flex-1 flex flex-col items-center justify-center gap-3 p-4">
      <Text block variant="h5" className="font-serif text-center">
        {title}
      </Text>

      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-center gap-3">
          {!shouldHideFeedback && (
            <FullButton
              onClick={() => reportIssue()}
              size="sm"
              variant="solid"
              colorScheme="primary"
            >
              Report an issue
            </FullButton>
          )}

          <FullButton onClick={() => reloadPage()} size="sm" variant="outline">
            Refresh page
          </FullButton>
        </div>

        <div className="flex items-center justify-center gap-1">
          <FullButton onClick={() => goToStart()} size="sm" variant="link">
            Go to start
          </FullButton>

          {` · `}

          <FullButton
            onClick={() => isFeedbackModalOpenVar(true)}
            size="sm"
            variant="link"
          >
            Contact support
          </FullButton>
        </div>
      </div>
    </div>
  );
};
