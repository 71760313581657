import { toast, ToastOptions } from 'react-toastify';

import Text from '@/components/data-display/Text';

const baseToasterOptions: ToastOptions = {
  autoClose: 2500,
  closeOnClick: true,
  draggable: true,
  draggableDirection: 'y',
  hideProgressBar: true,
  pauseOnHover: true,
  position: 'top-center',
  progress: undefined,
};

interface ToastProps extends ToastOptions {
  title?: string;
  message?: string;
}

export const successToast = ({
  title,
  message,
  ...toastOptions
}: ToastProps) => {
  if (!title && !message) return null;

  return toast.success(
    <div data-testid="toast-success">
      <Text className="mt-2" variant="h4">
        {title}
      </Text>
      {message && <div className="mt-4">{message}</div>}
    </div>,
    { ...baseToasterOptions, ...toastOptions }
  );
};

const infoToast = ({ title, message, ...toastOptions }: ToastProps) => {
  if (!title && !message) return null;

  return toast.info(
    <div data-testid="toast-info">
      <Text className="mt-2" variant="h4">
        {title}
      </Text>
      {message && <div className="mt-4">{message}</div>}
    </div>,
    { ...baseToasterOptions, ...toastOptions }
  );
};

export const errorToast = ({
  title = 'Sorry. Something went wrong.',
  message,
  toastId = 'common-error-toast', // Default `toastId` to prevent duplicate toasts
  ...toastOptions
}: ToastProps) => {
  return toast.error(
    <div data-testid="toast-error">
      <Text className="mt-2 text-white" variant="h5">
        {title}
      </Text>
      {message && <div className="mt-4">{message}</div>}
    </div>,
    {
      ...baseToasterOptions,
      autoClose: 5000,
      toastId,
      ...toastOptions,
    }
  );
};

export const warnToast = ({
  title = "Something doesn't look right.",
  message,
  ...toastOptions
}: ToastProps) => {
  return toast.warn(
    <div data-testid="toast-warn">
      <Text className="mt-2 text-white" variant="h5">
        {title}
      </Text>
      {message && <div className="mt-4">{message}</div>}
    </div>,
    { ...baseToasterOptions, ...toastOptions }
  );
};

export default infoToast;
